import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { Hero, Features, Materials, Copy, CallToAction, RegistudyFeatures } from "../sections"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />
    <Features />
    <Materials />
    <Copy />
    <CallToAction />
    <RegistudyFeatures home={true} />
  </Layout>
)

export default IndexPage
